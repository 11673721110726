import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { Button, Container, Stack, Typography } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

const query = graphql`
  query DatenschutzData {
    contentfulDatenschutz(id: { eq: "6aaa7a34-a1c9-57ec-b94f-7aacdfbf0b77" }) {
      content {
        content
      }
    }
  }
`;

export default function Datenschutz() {
  const { contentfulDatenschutz } =
    useStaticQuery<Queries.DatenschutzDataQuery>(query);
  return (
    <Container>
      <Stack spacing={6} alignItems="center">
        <Typography variant="h1" sx={{ textAlign: `center` }}>
          Datenschutz
        </Typography>
        {contentfulDatenschutz?.content?.content && (
          <Typography>
            <ReactMarkdown>
              {contentfulDatenschutz?.content?.content}
            </ReactMarkdown>
          </Typography>
        )}
        <Button
          component={GatsbyLink}
          to={`/`}
          variant="text"
          sx={{ width: 100, textTransform: `none` }}
          startIcon={<ArrowLeft />}
        >
          Zurück
        </Button>
      </Stack>
    </Container>
  );
}
